
#tableStyle3 {
    background-color: transparent;
    color: #fff;
    padding: 20px;
    border-radius: 5px;
    height: 100%;
}
#pagin{
    margin-top: 10px
}
#floatR{
    float: right;
}

.btn-style {
    padding: 10px;
    font-size: 18px;
    float: left;
    font-weight: 700;
    color: #fff;
}

#Style > .fa {
    margin-left: 7px;
    font-size: 17px;
}

#contenedor-2 {
    margin-top: 51px;
    font-size: 13px;

}

#formularioLabel {
    color: #333333;
    font-weight: 600;
}

#formStyle {
    line-height: 16px;
    font-size: 17px;
    color: #6a5c69;

}

#divLabel {
    margin-left: 4px;
}

#modal-title {
    margin-left: 20px;
}

.modal-backdrop {
    padding: 30px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100% !important;
    height: 100% !important;
    background-color: #000;
}

@media (min-width: 576px) {
    .modal-dialog {
        padding: 30px;
        max-width: 90% !important;
    }
}





