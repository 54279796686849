.styleCard{
    background: #333;
    height: 100%;
    border: 1px solid #4f4d4d;
}

.styleCardHeader{
    background: #3b3b3b52;
    border:none;
}
.styleTitulos{
    font-size: 1rem;
    color:#fff;
    font-weight: 700;
}
.styleBtn{
    width: 100%;
    border:none;
    color: white;
    background: #ef3937;
}

